@import "./variables.scss";


.full-star {
    background-color: $favIconColor;
    mask-image: url(../public/icons-svg/control/full-star.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 1em;
}

.app-toolbar-icon-st0 {
    stroke: transparent;
    fill: $appToolbarColor;
}

.full-star-icon-st0 {
    stroke: none;
    fill: $favIconColor;
}

.link-icon-font-icon {
    stroke: none;
    fill: $color2;
    text-align: center;
    width: 1.4em;
    margin-left: -0.25em;
    height: 100%;
    display: block;
}

.empty-star-icon-st0 {
    fill: none;
    stroke: $favIconColor;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.empty-star {
    background-color: $favIconColor;
    stroke: none;
    fill: $favIconColor;
    mask-image: url(../public/icons-svg/control/empty-star.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 1em;
}

.search-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/Search.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.search-icon-st0 {
    stroke: none;
    fill: $appToolbarColor;
}

.home-toolbar-color {
    stroke: none;
    fill: $homeToolbarColor;
    background-color: $homeToolbarColor;
}

.share {
    background-color: $homeToolbarColor;
    stroke: none;
    fill: $shareBtnColor;
    mask-image: url(../public/icons-svg/fiche/share-alt-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 2em;
}

.sticky-note-icon {
    background-color: $homeToolbarColor;
    stroke: none;
    fill: $homeToolbarColor;
    mask-image: url(../public/icons-svg/homeToolBar/sticky-note-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 2em;
}

.chevron-right-icon-st0 {
    stroke: none;
    fill: $listChevronColor;
    font-size: 2em;
}

.chevron-right-icon {
    background-color: $listChevronColor;
    stroke: none;
    fill: $listChevronColor;
    mask-image: url(../public/icons-svg/control/chevron-droit-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 2em;
    width: 2em;
    min-width: 2em;
}

.back-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/appToolBar/fleche.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.burger-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/appToolBar/Burger.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.home-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/Home.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.phone-row {
    background-image: url(../public/icons-svg/fiche/telephone.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 3em;
    width: 3em;
}

.link-row {
    background-image: url(../public/icons-svg/fiche/lien.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 3em;
    width: 3em;
}

.email-row {
    background-image: url(../public/icons-svg/fiche/contact.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 3em;
    width: 3em;
}

.row-st0 {
    fill: $color2; 
    stroke: none;
}

.row-st1 {
    fill: white; 
    stroke: none;
}

.map-row {
    background-image: url(../public/icons-svg/fiche/map.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 3.26em;
    width: 3.26em;
    margin-left: 0.1em;
}

@media (min-width:768px) {
    .map-row {
        background-image: url(../public/icons-svg/fiche/map.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 2.35em;
        width: 3em;
        margin-left: -0.45em;
    }
}

.filter-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/appToolBar/filter-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.sync-icon {
    mask-image: url(../public/icons-svg/klipso/sync-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1.5em;
}

.calendar-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/appToolBar/calendar-alt-regular.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.calendar-week-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/appToolBar/calendar-week-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1.2em;
    margin: 0 0.1em;
    padding: 0 0.1em;
}

.list-icon {
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-image: url(../public/icons-svg/appToolBar/list-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.user-icon {
    mask-image: url(../public/icons-svg/Profil.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 2em;
    width: 2em;
    display: flex;
}

.user-fiche-icon {
    height: 1.7em;
    width: 2.5em;
    background-color    : $color2;
}

.envelope-icon {
    background-color: $homeToolbarColor;
    stroke: none;
    fill: $homeToolbarColor;
    mask-image: url(../public/icons-svg/homeToolBar/envelope-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 2em;
    width: 2em;
}

.klipso-icon {
    background-color: $homeToolbarColor;
    fill: $homeToolbarColor;
    stroke: none;
    fill: $homeToolbarColor;
    mask-image: url(../public/icons-svg/KlipsoLead.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 2em;
    width: 2em;
}

.qr-code-icon {
    background-color: $homeToolbarColor;
    stroke: none;
    fill: $homeToolbarColor;
    mask-image: url(../public/icons-svg/homeToolBar/qrcode-reader.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 2em;
    width: 2em;
}

.times-icon {
    mask-image: url(../public/icons-svg/control/times-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 0.6em;
    width: 1em;
}

.home-toolbar-st0 {
    fill     : $homeToolbarColor;
    stroke: none; 
}

.search-field-cancel-icon {
    fill     : $searchFieldBorderColor;
    stroke: none;
    background-color: $searchFieldBorderColor;
    height: 1em;
    cursor    : pointer;
    -webkit-tap-highlight-color: transparent;
}

.times-circle-icon {
    mask-image: url(../public/icons-svg/control/times-circle-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 2em;
    mask-size: 80%;
}

.times-square-icon {
    mask-image: url(../public/icons-svg/control/square-xmark-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.globe-icon {
    mask-image: url(../public/icons-svg/fiche/globe-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1.5em;
    width: 1.5em;
    display: block;
    stroke: none;
    fill: grey;
    background-color: grey;
}

.langue-icon {
    mask-image: url(../public/icons-svg/langue.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1.5em;
    width: 1.5em;
    display: block;
    stroke: none;
    fill: grey;
    background-color: $menuItemIconsBackgroundColor;
}

.pmr-icon {
    mask-image: url(../public/icons-svg/pmr.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1.5em;
    width: 1.5em;
    display: block;
    stroke: none;
    fill: grey;
    background-color: $menuItemIconsBackgroundColor;
}

.comments-icon {
    mask-image: url(../public/icons-svg/fiche/comments-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 3em;
    width: 3em;
    display: block;
    stroke: none;
    fill: $ficheIconsColor;
}

.twitter-icon {
    mask-image: url(../public/icons-svg/social/twitter-square-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: #1DA1F2;
    display: block;
}

.facebook-icon {
    mask-image: url(../public/icons-svg/social/facebook-square-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: #4267B2;
    display: block;
}

.instagram-icon {
    mask-image: url(../public/icons-svg/social/instagram-square-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: #E1306C;
    display: block;
}

.linkedin-icon {
    mask-image: url(../public/icons-svg/social/linkedin-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: #0e76a8;
    display: block;
}

.youtube-icon {
    mask-image: url(../public/icons-svg/social/youtube-square-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: #FF0000;
    display: block;
}

.tiktok-icon {
    mask-image: url(../public/icons-svg/social/tiktok-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: #010101;
    display: block;
}

.angle-right-icon {
    mask-image: url(../public/icons-svg/control/angle-right-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.chevron-down-icon {
    mask-image: url(../public/icons-svg/control/chevron-down-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 1em;
    background-color: $listChevronColor;
    stroke: none;
    fill: $listChevronColor;
}

.lock-icon {
    mask-image: url(../public/icons-svg/login/lock-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.arrow-up-icon {
    mask-image: url(../public/icons-svg/control/arrow-up-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.arrow-down-icon {
    mask-image: url(../public/icons-svg/control/arrow-down-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.spinner-icon {
    mask-image: url(../public/icons-svg/control/spinner-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 5em;
    width: 4em;
    background-color: #0c9e0c;
    stroke: none;
    fill: #0c9e0c;
}

.pencil-icon {
    mask-image: url(../public/icons-svg/login/pencil.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1.5em;
    width: 2em;
}

.sms-icon {
    mask-image: url(../public/icons-svg/social/sms-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.university-icon {
    mask-image: url(../public/icons-svg/fiche/university-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 2em;
    height: 1.7em;
    display: block;
    stroke: none;
    fill: $ficheIconsColor;
}

.building-icon {
    mask-image: url(../public/icons-svg/fiche/building-regular.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1.5em;
    width: 2em;
}

.tag-icon {
    mask-image: url(../public/icons-svg/fiche/tag-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    display: block;
}

.newspaper-icon {
    mask-image: url(../public/icons-svg//fiche/newspaper-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    display: block;
}

.file-icon {
    mask-image: url(../public/icons-svg/fiche/file-alt-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}
.apple-icon {
    mask-image: url(../public/icons-svg/control/apple-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.apple-icon {
    mask-image: url(../public/icons-svg/control/apple-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.android-icon {
    mask-image: url(../public/icons-svg/control/android-brands.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}


.arrow-alt-circle-up-icon {
    mask-image: url(../public/icons-svg/control/arrow-alt-circle-up-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.check-icon {
    mask-image: url(../public/icons-svg/control/check-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.cog-icon {
    mask-image: url(../public/icons-svg/control/cog-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.check-circle-icon {
    mask-image: url(../public/icons-svg/control/check-circle-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 2em;
}

.check-square-icon {
    mask-image: url(../public/icons-svg/control/check-square-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.minus-icon {
    mask-image: url(../public/icons-svg/control/minus-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.circle-icon {
    mask-image: url(../public/icons-svg/login/circle-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1.1em;
    width: 1.1em;
}

.square-icon {
    mask-image: url(../public/icons-svg/control/square-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.camera-alt-icon {
    mask-image: url(../public/icons-svg/login/camera-alt-3.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: black;
    stroke: none;
    fill: black;
}

.images-icon {
    mask-image: url(../public/icons-svg/login/image-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: black;
    stroke: none;
    fill: black;
}

.chevron-left-icon {
    mask-image: url(../public/icons-svg/control/chevron-left-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 1em;
    background-color: $listChevronColor;
    stroke: none;
    fill: $listChevronColor;
    ;
}

.envelope-square-icon {
    mask-image: url(../public/icons-svg/social/envelope-square-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.play-circle-icon {
    mask-image: url(../public/icons-svg/fiche/play-circle-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}

.key-icon {
    mask-image: url(../public/icons-svg/login/key-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.external-link-icon {
    mask-image: url(../public/icons-svg/fiche/external-link-alt-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.arrow-left-icon {
    mask-image: url(../public/icons-svg/control/arrow-left-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1.5em;
}

.suitcase-icon {
    mask-image: url(../public/icons-svg/fiche/suitcase-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1.5em;
    width: 2em;
}

.pen-icon {
    mask-image: url(../public/icons-svg/login/pen-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.phone-alt-icon {
    mask-image: url(../public/icons-svg/fiche/phone-alt-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.history-icon {
    mask-image: url(../public/icons-svg/klipso/history-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.ellipsis-icon {
    mask-image: url(../public/icons-svg/klipso/ellipsis-h-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
    background-color: $klButtonsColor;
    stroke: none;
    fill: $klButtonsColor;
}

.user-circle-icon {
    mask-image: url(../public/icons-svg/login/user-circle-solid.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1.4em;
    width: 1.4em;
}

.lock-alt-icon {
    mask-image: url(../public/icons-svg/login/lock-alt.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 1em;
}

.icon-lg {
    font-size: (4em / 3);
    line-height: (3em / 4);
    vertical-align: -15%;
}

.icon-2x {
    font-size: 2em;
}

.icon-3x {
    font-size: 3em;
}

.icon-4x {
    font-size: 4em;
}

.icon-5x {
    font-size: 5em;
}

.icon-fw {
    width: (18em / 14);
    text-align: center;
}

.icon-flip-horizontal {
    transform: rotateY(180deg); // Rotate 180 degrees along the y-axis
}

.social-icon {
    mask-image: url(../public/icons-svg/social/th-solid.svg);
    background-color: $appToolbarColor;
    stroke: none;
    fill: $appToolbarColor;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1.2em;
    width: 1.2em;
}

.close-modal-btn-google-map {
    display: block;
    background-color: black;
    stroke: none;
    fill: black;
}

.pdf-icon {
    mask-image: url(../public/icons-svg/fiche/pdf.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    height: 1em;
    width: 1em;
    font-size: 1.2em;
    display: block;
    background-color: $ficheIconsColor;
}