@import "./variables.scss";

[id="home-toolbar"] {
  flex-shrink: 0;
  display: flex;
  margin: 0;
  padding: 0;
  color: $homeToolbarColor;
}

[id="home-toolbar"] .ht-button {
  // background: linear-gradient(#589dc3, #4186c0);
  background: $color1;
}

.list-component li {
  border: none;
  border-bottom: 1px solid $listBorderColor;
}

#home-toolbar {
  padding: 0.3rem 0;
  background: white;
}

.menu-items-container {
  .menu-item {
    align-items: center;
  }

  .menu-icon {
    height: 25px;
    text-align: center;
    color: $color2;

    &.icon-font {
      line-height: 1.2em;
    }

    &.fa {
      &.top3 {
        padding-top: 3px;
      }

      &.bottom5 {
        padding-bottom: 5px;
      }
    }
  }
}

.list-el-text {
  font-weight: bold;
}

.list-el-event .start-date {
  color: $fontColor;
}

.text-minor {
  font-weight: normal;
}

// Raise text padding due to printania font
.list-el-text,
.menu-items-container .menu-text {
  padding-top: 0.2em;
}

.toolbar>.center {
  padding-top: 0.2em;
}

.type-bar-label {
  padding-top: 3px;
  font-weight: bold !important;

  small {
    font-weight: normal !important;
  }
}

.is-android .type-bar-count {
  padding: 0 4px 0 3px;
}

// Category counter
.is-android .list-el-counter>span {}

.is-ie .list-el-counter>span {}

.list-el-counter>span {
  padding: 0.2em;
}

.home-tiles {
  color: $color1;
  margin-top: 10px;
}

.home-tile {
  margin-top: 10px;
}

.home-tile-subcontainer {
  margin: 15px;
  border-radius: 4px;
  // padding-top: 15px;
  padding: 15px;
}

.home-tile-legend {
  text-align: center;
  justify-content: center;
  // align-items: center;
}

.home-btn-text .home-tile-legend-label,
.home-btn-ad .home-tile-legend-label {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.2em;
  color: $tileTextColor;
  font-weight: bold;
}

.home-btn-text .home-tile-legend-icon {
  height: 64px;
}

.home-btn-ad .home-tile-legend-icon {
  height: 64px;
}

.home-tile-legend-icon.icon-font {
  font-size: 1.6em;
}

.home-btn2-text .home-tile-legend {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;

  .home-tile-legend-label {
    margin-top: 7px;
  }

  .home-tile-legend-icon {
    height: auto;
  }

  color: white;
}

.list-component.speakers {
  .itemWrapper {
    order: 2;
  }

  // NB: flex `order` default value is 0
  .list-el-image {
    order: 1;
    margin: 0 0.5em 0 0.1em;
    //min-width:80px;
    object-fit: contain;
    border-radius: 10%;
  }

  .list-el-chevron {
    order: 3;
  }
}

.list-component.participants {
  .list-el-image {
    max-width: 40px;
  }
}

@media (min-aspect-ratio: 1/2) and (max-width: 400px) {
  .home-tiles {
    margin-top: 10px;
  }

  .home-btn-text .home-tile-legend-icon {
    height: 60px;
  }

  .home-btn-ad .home-tile-legend-icon {
    height: 60px;
  }

  .home-tile-subcontainer {
    margin: 0px;
    border-radius: 4px;
    padding-top: 0px;
  }

  .home-tile-legend-label {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media (max-width: 320px) {
  .home-tiles {
    margin-top: 0px;
  }

  .home-btn-text .home-tile-legend-icon {
    width: 50px;
    // margin: 0 0 0 30px;
    margin: 0 auto;
  }

  .home-btn-ad .home-tile-legend-icon {
    height: 64px;
  }

  .home-tile-subcontainer {
    margin: 0 0 0 0;
    border-radius: 4px;
    padding-top: 0px;
    width: 104px;
  }

  .home-tile-legend-label {
    font-size: 0.7em !important;
    line-height: 1.1em;
  }
}

@media (max-width: 767px) {
  .home-tile:nth-child(3n+1) {
    border-left-color: transparent;
  }
  .home-tile:nth-child(3n) {
    border-right-color: transparent;
  }
}


.home-toolbar-button:not(:first-child) {
  margin-left: 2px;
}

.fa-lang:before {
  content: "\f0ac";
}

.fa-cgu:before {
  content: "\f15c";
}

.fa.connected {
  color: #a1c308;
}

.mobilespot-bar {
  // display: none;
  // justify-content: end;
  color: #ffffff;
  height: 4em;
  // visibility: hidden; // keep bar but hide it
  // background:#EAEBE6 10% center / 50px no-repeat url('/files/project/home/Palexpo_logo.png');
  background: #EAEBE6;
  background-size: unset;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.1rem;

  & .link.terms-of-use {
    display: flex;
    align-items: center;
    margin-right: 2em;

    &.colored-link {
      font-weight: 600;
      color: $tileTextColor !important;
    }
  }

  &>a:first-child {
    justify-content: start;
    width: 40%;
    background: #FFF 30% center no-repeat url('/files/project/home/Palexpo_logo.png');
  }
}

.choose-profile-button {
  border-radius: 5px;
}

.logo.img-full-width {
  margin: 20px;
}

.title-image {
  flex-basis: 45px;
  background-size: contain;
  background-position-y: center;
  border-radius: 0px;
  height: 45px;
  margin: 0 0.2em 0 0;
}

.title-image-small {
  flex-basis: 24px;
  background-size: contain;
  background-position-y: center;
  border-radius: 0px;
  height: 24px;
  margin: 0 0.1em 0 0;
}

.detail-speakers-component .type-bar.horizontal-separator img {
  height: 50px;
}

#home-content {
  padding: 0px;
  background-position: center 0% !important;
}

.type-bar-content {
  transition: max-height 0.5s, opacity 0.5s;
}

.fiche-row1 {
  margin-bottom: 1.5em;
}

.fiche .fiche-row1 {
  margin-bottom: 0.5em;
}

// USER DATA (profile)

.ud-section-fields {
  &>label {
    font-size: 0.9em;
  }
}

.ud-section-fields {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.ud-section-switches {
  padding: 1em;
  margin-top: 0.5em;
  // border : 1px grey solid;
  // border-radius : 5px;
}

.input-switch-container {
  margin: 1em 0 0.2em 0;
}

#ud-form input {
  padding: 0.3em 0.5em;
}

#profile-page-content {
  padding: 0;
}

.fiche-description .prop-name .prop-description p b {
  font-size: 1.25em;
}

.ht-button {
  align-items: center;
}

.app-toolbar .app-toolbar-icon > div {
  display: flex;
  align-items: center;
}

.list-index-element-selected {
  color: $color1;
}

.event-page .start-date, .event-page .start-time span {
  font-weight: 500;
}

.klh-title {
  background: none;
  width: auto;
  height: auto;
  font-size: 1em;
}

#favorites-code-dialog {
  margin: 1em 1em 0em;

  .wrapper {
    text-align: center;
  }

  .body {
    overflow-y: auto;
  }
}

.MuiDialog-paperWidthSm {
  width: 480px;
}

.mobigeo-container {
  .pins .pin {
    .label {
      background-color: white;
    }
  }
}