

$pageBackgroundColor: white;
$ficheBackgroundColor: white;

$color1: #c22458;
$color1lighter: lighten( $color1, 30% );

$color2: $color1;
$color3: #0094CB;

// $fontColor: #404040;
$fontColor: $color1;

$titleFontColor: white;

$lightGrey: #B0B0B0;

$linkColor: #0089D9;
$klLinkTextColor:$linkColor;
$klLinkBorderColor:$linkColor;
$warningColor: #ffc107;

$list-el-event: #42c7a8;
$endHourColor:  $color1;

/* Two columns mode */
$leftColumnsWidth: 34%;
$rightColumnsWidth: 66%;

/* Call-to-action button (e.g main button on modals) */
$ctaBtnBackgroundColor: $color1;

/* App toolbar */
$appToolbarHeight: 48px;
$appToolbarColor: $color2;
$appToolbarBackgroundColor: white;

/* Fiche */
$favIconSize: 34px;
$ficheIconsSize: 34px;
$ficheIconsColor: $color2;


/* Lang */
$currentLangBorderColor: #3eaac3;
/* in menu */
$menuLangIconSize: 2.4em;
$menuLangIconPosition: 20% center;
$menuItemHoverBackgroundColor: #ececec;
$menuItemIconsBackgroundColor: $color2;


/* Home tile click effect */
$tileEffectScale: .96;
$tileEffectColor: white;
$tileEffectOpacity: .3;

$tileIconSize: 48px;
$tileTextColor: #757673;
/* (Inbox empty, no result for search & favoris page ) tile text color */
$noResultTextColor: #757673;

/* Home toolbar */
$homeToolbarColor: #ffffff;
$homeToolbarBackgroundColor: $color1;
$homeToolbarBackgroundColorHover: $color1lighter;

$inboxCountBackgroundColor: white;
$inboxCountColor: $color1;

$homeBackgroundColor: #f6f6fa;

/* Banner component (!= AdBanner) */
$bannerBackgroundColor: transparent;
$bannerMaxHeight: 90px;

/* Share button */
$shareBtnColor: white;

/* List */
$favIconColor: #F5A400;
$listElBackgroundColorHover: #f2f2f2;
$listChevronColor: $lightGrey;
$listBorderColor: #d3d3d3;

@mixin emphasized() {
    color: #FF0614;
}
$indexHorizontalBarColor: white;

/* @see .horizontal-separator, used by ListElement, TypeBar */
$separatorBackgroundColor: $color2;
$separatorColor: white;

$listTextMinorStrongColor: #7b7b7b;
$listTextMinorStrongFontSize: 1em;

$listTextMinorColor: #565656;
$listTextMinorFontSize: .8em;

$listElCounterBackgroundColor: #e2e2e2;
$listElementImageSize: auto;
$listElementImageWidth: 60px;
$listElementImageSmallWidth: 32px;

/* Side index */
$sideIndexColor: white;
$sideIndexGradient: linear-gradient(to bottom, $color1, $color2);
$sideIndexBackgroundColor: $color1;
$sideIndexSelectedBackgroundColor: #417497;
$sideIndexSelectedColor: white;

/* Search */
$searchFieldHeight: 3.5em;
$searchFieldBackgroundColor: white;
$searchFieldBorderColor: #a8a8a8;
$searchFieldClearButtonColor: white;
$searchFieldClearButtonBgColor: $color2;

/* Input */
$placeholderText: #656555;

/* TypeBar */
$typeBarIconColor: white;
$typeBarColor: white;

/* Mobile-Spot Bar */
$mobileSpotBarColor: $fontColor;
$mobileSpotBarBackgroundColor: indigo;

/* Misc */
$chevronColor: $lightGrey;

/* Filter dialog colors */
$fdLightest: #f8f8f8;
$fdLighter: #e3e3e3;
$fdLight: #eeeeee;
$fdMid: #a6a6a6;
$fdDarker: #707070;
$fdSelected: #008b3d;
$fdSelectedIcon: #ff7923;
$fdWarningColor: #ff7923;
$fdLinkColor : #60b3db;

/* Synoptic agenda */
$synoColumnWidth: 116px;
$synoBackgroundColor: white;
$synoPlacesRowHeight: 60px;
$synoBorderColor: lightgrey;
$synoHoursColumnWidth: 1.6em;
$synoEventTextColor: white;

/* image upload */
$imageUploadCameraBtnColor: lighten( $color1lighter, 15% );
$imageUploadGalleryBtnColor: lighten( $lightGrey, 15% );

/* image crop */
$imageCropCancelBtnColor: grey;
$imageCropConfirmBtnColor: #39b939;


/* KLIPSO LEADS */

$klTextColor: #646464;

$klThemeColor1: #004997;
$klThemeColor2: #0094CB;

$klHeaderColor: #FFFFFF;
$klHeaderBgColor: $klThemeColor2; // See also config/cordovaStatusBarConfig.js
$klHeaderHeight: 3em;
$klHeaderCloseBtnColor: #FFFFFF;

$klContentColor: $fontColor;
$klContentBgColor: $pageBackgroundColor;

$klStatusBlockBgColor: #efefef;
$klAllSyncColor: #00bf00;

$klCheckColor: #00bf00;
$klNotSyncColor: $klThemeColor1;

$klButtonsColor: white;
$klButtonsBgColor: $klThemeColor1;

$klFooterHeight: 3.5em;

$sortSearchBarHeight: 1.6em;

$klFieldBorder: 1px solid lightgrey;

$klOverlayLoaderBgColor: rgba(255, 255, 255, 0.7);
$klOverlayLoaderLabelBgColor: white;

// Klipso Leads questionnaire screen

$klFormMarginLeft: 50px;

$klFormContactRow1BgColor: #efefef;
$klFormCompanyIconColor: $klThemeColor1;
$klFormCompanyNameColor: $klThemeColor1;
$klFormNameIconColor: $klThemeColor2;

$klFormContactRow2BgColor: #f8f8f8;
$klFormContactRow2BorderBottom: 2px solid #efefef;
$klFormIconOtherColor: #aaaaaa;
$klMandatoryFieldNotSetColor: black;
$klFormTitleBgColor: #f8f8f8;
$klChevronColor: $klThemeColor2;
$klCheckBoxContainerBgColor: white;
$klCheckBoxColor: $klThemeColor2;

$klFormHistoryColor: #aaaaaa;

$klSearchFieldBottomBorder: 1px solid #dfdfdf;

$klEmsLuIconsColor: #aaaaaa;
$klEmsLuLabelsColor: #aaaaaa;
$klEmsLuValuesColor: $klTextColor;
$klEmsResetLicenceColor: #aaaaaa;
$klEmsHorizontalBorder: 2px solid #efefef;

$klEmsExportIconColor: $klThemeColor2;

$klLinkBorderColor:$linkColor;
$klLinkTextColor:$linkColor;

$klwarningColor: red;

// synoptic agenda color of tab selected 
$synopticAgendaTabSelected: red !important;
$vimeoBorderColor: transparent;